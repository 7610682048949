/** CKEDITOR **/

#content.cke_show_borders {
  // max-width: 1200px;
  // margin: 0 auto;
  // font-size: 1rem;
  // background: #fff;

  //  .btn {
  //     cursor: default;
  //     -webkit-touch-callout: auto;
  //     -webkit-user-select: auto;
  //     -khtml-user-select: auto;
  //     -moz-user-select: auto;
  //     -ms-user-select: auto;
  //     user-select: auto;
  //   }

  // set icons for lists
  ul {
    padding-left: 0.5em;

    > li {
      &::before {
        content: "\203a";
        color: #067aac;
        /*top: -0.25em;*/
        position: relative;
        left: -5px;
        font-size: 130%;
      }
    }
  }

  /* duplicate of custom css, colors for bulleted lists inside cards */
  .card-contact ul > li::before,
  .card-secondary ul > li::before,
  .card-tertiary ul > li::before {
    color: #ffffff;
  }

  .col-md-4,
  .col-md-6,
  .col-md-8 {
    border: 1px dotted #bcbcbc !important;
  }
  .card {
    /* this is to make kuleuven cards editable by double clicking at the top */
    padding-top: 10px;
  }
  /* show contents of collapse */
  .collapse {
    display: block;
  }
  /* show contents of modals */
  .modal {
    display: block;
    position: relative;
    padding: 2em 1em;
    border: 1px dashed #ccc;
  }
  /* show contents of tabs */
  //   .fade {
  //     opacity: 1;
  //   }
  //   .tab-content > .tab-pane {
  //     display: block;
  //     padding: 1em;
  //     border: 1px dashed #ccc;
  //   }
  /* fix for icon links */
  a:before,
  a:after {
    display: inline;
  }
}

/* end ckeditor */
