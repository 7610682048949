.six.at_assoc {
  .global-header {
    background: #10485d;
    border-bottom: 36px solid #fff;

    .container:first-child {
      height: 40px;
    }

    .logo {
      content: url("https://stijl.kuleuven.be/associatie/images/logo_asso_stripe.png");
      width: 160px;
      height: 68px;
      top: 7px;
    }
  }

  .flyout {
    > a {
      display: none;
    }
    #portal-globalnav {
      width: 100%;
    }
  }

  .local-footer .footer-navbar {
    background-color: #10485d;
  }

  /* footer logos */
  footer.global-footer .bottom-footer .assopartners {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 2em;

    a {
      opacity: 0.6;
      transition: opacity ease-in-out 0.125s;

      &:hover {
        opacity: 1;
        transition: opacity ease-in-out 0.125s;
      }
    }
  }
}
