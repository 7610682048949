/* list reset */
#content {
  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    margin-bottom: 1rem;
  }

  ol,
  ul ul,
  ol ol,
  ol ul,
  ul ol {
    padding-left: 1em;
  }
}

// TOOLBAR

// hide unnecessary blocks view in toolbar
#edit-zone {
  /* fix to make toolbar scrollable to end, but the dropdowns do not open in toolbar */
  // overflow-y: auto;

  a#plone-contentmenu-display-blocks_onecol_25_view,
  a#plone-contentmenu-display-blocks_onecol_50_view,
  a#plone-contentmenu-display-blocks_twocol_50_view,
  a#plone-contentmenu-display-blocks_twocol_top_view,
  a#plone-contentmenu-display-blocks_threecol_top_view,
  a#plone-contentmenu-display-blocks_fourcol_top_view,
  a#folder-blocks_fourcol_top_view,
  a#folder-blocks_threecol_top_view,
  a#folder-blocks_twocol_top_view,
  a#folder-blocks_twocol_50_view,
  a#folder-blocks_onecol_50_view,
  a#folder-blocks_onecol_25_view {
    display: none;
  }
  .dropdown-menu {
    min-width: 15rem;

    > li > a {
      background: inherit;
    }
  }
}
// Resolve conflicts whith default theme
.card {
  --bs-card-border-radius: 0;
  --bs-card-inner-border-radius: 0;
  word-wrap: inherit;
}
.navbar-nav li {
  background-color: #fff;
}

.navbar-nav li a:hover {
  background-color: #fff;
}

.nav-main li a {
  padding: 8px 0;
}

// breadcrumbs

.breadcrumb {
  --bs-breadcrumb-item-padding-x: 0;
}
#portal-breadcrumbs {
  //font-size: 0.875rem;
  .container,
  .breadcrumb {
    padding-left: unset;
  }
  background-color: inherit;
  margin-bottom: 0px;
}

//CONTENT

// description
.description {
  font-size: 1.125rem;
}

// todo: table-responsive: remove display: block for plone edit ui (sharing and portal_registry)

// make leadimages visible
// on events
/*.event-summary {
  z-index: -1;
}*/

//on newsitems & pages
/*#section-leadimage figure*/
.newsImageContainer {
  position: relative;
  z-index: 1;
}

// Events
.event-summary {
  .event-occurences {
    margin-bottom: 0.5rem;
  }
  .event-when {
    margin-bottom: 1rem;
  }

  abbr[data-original-title],
  abbr[title] {
    text-decoration: none;
  }

  .event-all-dates a {
    text-decoration: none;
  }
  .meta-icon {
    font-size: 1em;
    margin-top: 0;
  }
}

// event collection view
.vevent {
  .cal_date {
    min-width: 150px;
    max-width: 150px;
  }
  abbr[title] {
    text-decoration: none;
  }
}

// content
#content {
  #section-next-prev a.btn {
    border-color: #004070;
    color: #004070;
  }

  #simple_listing_view {
    @media (min-width: 800px) {
      columns: 2;
    }

    li::before {
      content: "\203a";
      color: #067aac;
      top: -0.25em;
    }
  }

  #parent-fieldname-text {
    // lists
    ul[style^="list-style-type"] li {
      list-style-type: none;
    }
    ol > li::before {
      font-size: inherit;
    }
    ul {
      margin-bottom: 1rem;

      li + li {
        margin-top: 5px;
      }

      > li {
        padding-left: 16px;
      }
      > li::before {
        content: "\203a";
        color: #067aac;
        top: -0.25em;
        font-size: 130%;
      }

      li {
        position: relative;
      }

      li::before {
        position: absolute;
        left: 0;
        display: block;
      }
    }

    .dropdown ul li::before {
      content: "";
    }

    li[style^="list-style"]::before {
      content: "";
    }

    ol {
      padding-left: 1em;
    }

    ul.campus-labels > li::before {
      font-family: Material Icons;
      content: "\e55f";
      color: #fff;
      font-feature-settings: "liga";
      vertical-align: middle;
      display: inline-block;
      position: relative;
      padding-top: 3px;
    }

    .card-secondary ul.campus-labels > li::before,
    .card-tertiary ul.campus-labels > li::before {
      color: #004070;
    }

    .card-contact ul > li::before,
    .card-secondary ul > li::before,
    .card-tertiary ul > li::before {
      color: #ffffff;
    }

    // activity-lists
    .activity-list li {
      padding-left: 0px;

      &::before {
        content: "";
      }
    }
  }
}

// related items widget
.select2-drop {
  a:not([href]):not([tabindex]),
  a:not([href]):not([tabindex]):focus,
  a:not([href]):not([tabindex]):hover {
    color: initial;
  }
  .pat-relateditems-result-browse svg {
    width: 30px;
    height: 45px;
    color: #067aac;
  }
}

// insert itemdate customization (.eg blocks for news with publicationdate)
.itemdate,
.blocks .icon-place-before {
  color: #004070;
}

.itemdate:before {
  font-family: Material Icons;
  content: "today";
  margin-right: 5px;
  vertical-align: top;
  -moz-font-feature-settings: "liga";
  font-feature-settings: "liga";
}

p.itemdate {
  margin-bottom: 0.5rem;
}

// FORMS
// form fieldset divider
#content form.easyformForm:not(.pat-autotoc) > fieldset:not(#fieldset-default) {
  border-top: 3px solid #e6f4ff;
  // margin: 1em 0;
}

/* hide title for default fieldset (Default/Standaard) */
#fieldsetlegend-default {
  display: none;
}

#content #form fieldset {
  margin: 1em 0;
}

#content #form > fieldset > legend {
  color: #004070;
  font-family: "Source Serif Pro", serif;
  margin: 1em 0;
}

// form label
#content #form .form-label {
  font-weight: bold;
}

// likert outline
#content #form .likert-widget .even td:first-child {
  width: 30%;
}

// fieldset to label
form.easyformForm {
  // spacing for richlabel field and h2 inside
  label.horizontal {
    border-top: 3px solid #e6f4ff;
    width: 100%;
    margin: 1em 0;

    h2 {
      margin: 1em 0;
    }
  }
  #formPrologue ul li {
    padding-left: 0.5em;
    &::marker {
      content: "\203a";
      color: #067aac;
      top: -0.25em;
    }
  }
  #formEpilogue ul li {
    padding-left: 0.5em;
    &::marker {
      content: "\203a";
      color: #067aac;
      top: -0.25em;
    }
  }
}

//savedata hide clearall button
#form-buttons-clearall {
  display: none;
}

/* @end forms */

#content figure {
  display: table;
}

#content figcaption {
  display: table-caption;
  padding: 0.5em;
  background-color: #fff;
  color: #666;
  font-size: 0.875rem;
}

// leadimage
#section-leadimage {
  float: right;
  margin: 0 0 1em 1em;
  max-width: 400px;
}

// tables
.table.table-striped > thead > tr > th {
  color: #004070;
}

// folder_contents
/* icons in folder_contents not greyed out */
.template-folder_contents {
  .btn-outline-secondary {
    border: 1px solid #666;
    color: #666;
  }
  // input for foldercontents
  .pat-structure .form-check-input {
    position: relative;
    margin-left: auto;
  }
  .popover.attribute-columns.active {
    max-height: 25rem;
    overflow: auto;
  }
  .upload-queue > .previews {
    max-height: 15rem;
    overflow: auto;
  }

  // fix action icons in right column of folder_contents not being clickable
  .actionmenu-container .actionmenu .btn {
    z-index: 1;
  }
}

// tabular_view
body.template-tabular_view {
  .text-nowrap {
    white-space: initial !important;
  }

  svg {
    max-width: fit-content;
    vertical-align: middle;
  }
}

// hr
#content hr {
  opacity: initial;
}

// table of contents
.pat-autotoc .autotoc-nav {
  float: initial;
  margin: auto;
  margin-bottom: 18px;
}

#autotoc-container .autotoc-nav {
  border: none;
  margin: 0;
  a {
    display: block;
  }
  a:before {
    content: "\203a";
    padding-right: 0.5em;
    display: inline-block;
    text-decoration: none;
  }
  a:focus {
    outline-style: none;
  }
  .autotoc-level-2 {
    margin: 0 1em 0 0em;
  }
  .autotoc-level-3 {
    margin: 0 1em 0 1em;
  }
  .autotoc-level-4 {
    margin: 0 1em 0 2em;
  }
}

// hide email tab on add/edit Link
.view-name-add-Link .linkModal .autotoc-nav a#autotoc-item-autotoc-2,
.view-name-edit .linkModal .autotoc-nav a#autotoc-item-autotoc-2 {
  display: none;
}

// flyout - show changed to open in bs5
.flyout.collapse.open {
  display: block;
}
.flyout.collapse:not(.open) {
  display: none;
}
.flyout .nav-main > a {
  width: 90%;
}

// COMPONENTS

// ** overrides for bs4 kuleuven huisstijl **//
// modal
.modal-wrapper .modal.fade /* plone */,
.modal.fade /* bootstrap 5 */ {
  opacity: inherit;
  padding-top: 5em;
}
.modal-wrapper .modal.fade .modal-dialog {
  transform: none;
}
.modal-body ul li {
  padding-left: 0.5em;
  &::marker {
    content: "\203a";
    color: #067aac;
    top: -0.25em;
  }
}

// bootstrap 5 tabs
.tab-content .fade {
  opacity: inherit;
}
#content #parent-fieldname-text ul.nav-tabs li {
  font-weight: bold;
  &::before {
    content: "";
  }
  & + li {
    margin-top: 0;
  }
  .nav-link:focus,
  .nav-link:hover {
    background-color: #fff;
  }
}

// for accordion bs5
.accordion-collapse.show {
  display: block;
}
.accordion-collapse:not(.show) {
  display: none;
}

// for collapse element bs5
.collapse.show {
  display: block;
}
.collapse:not(.show) {
  display: none;
}

// alerts
.alert svg {
  width: 1em;
  height: 1em;
}

// END COMPONENTS

// used to be in css in plone 4 and used for example with images
.top {
  float: right;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.left {
  float: left;
  margin-right: 1em;
}

.right {
  float: right;
  margin-left: 1em;
}

// prevent footer overlap with popups
.l-page .local-footer .footer-navbar {
  z-index: 0;
}

// taxonomy control panel buttons (@@taxonomy-edit-data)
.taxonomy-tree .tree-view .info button {
  color:#004070;
}

/* icons for items in browse on server window */

.finder_panel .contenttype-document {
  background: no-repeat transparent 0px 0px
    url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
.finder_panel .contenttype-event {
  background: no-repeat transparent 0px -217px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
.finder_panel .contenttype-folder {
  background: no-repeat transparent 0px -649px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
// in plone 4 subsite is of type folder, so to keep consistency with plone 6, subsite also get folder icon
.finder_panel .contenttype-subsite {
  background: no-repeat transparent 0px -649px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
.finder_panel .contenttype-link {
  background: no-repeat transparent 0px -1081px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
.finder_panel .contenttype-news-item {
  background: no-repeat transparent 0px -1297px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
/*.finder_panel .contenttype-collection {background: no-repeat transparent 0px -1729px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);}*/
.finder_panel .contenttype-collection {
  background: no-repeat transparent 0px -1729px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
.finder_panel .contenttype-plone-site {
  background-image: none;
}

.finder_panel .contenttype-file {
  background: no-repeat transparent 0px -428px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
.finder_panel .contenttype-image {
  background: no-repeat transparent 0px -860px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}

.finder_panel .contenttype-document, .finder_panel .contenttype-event, .finder_panel .contenttype-folder, .finder_panel .contenttype-link, .finder_panel .contenttype-link, .finder_panel .contenttype-news-item, .finder_panel .contenttype-plone-site,
/*.finder_panel .contenttype-collection,*/.finder_panel .contenttype-collection, .finder_panel .contenttype-file, .finder_panel .contenttype-image {
  padding-left: 20px;
}

/* icons for items in relateditem widget and for @@search results */

.pat-relateditems-result-title.contenttype-document,
#search-results .contenttype-document {
  background: no-repeat transparent 0px 0px
    url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
.pat-relateditems-result-title.contenttype-event,
#search-results .contenttype-event {
  background: no-repeat transparent 0px -217px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
.pat-relateditems-result-title.contenttype-folder,
#search-results .contenttype-folder {
  background: no-repeat transparent 0px -649px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
// in plone 4 subsite is of type folder, so to keep consistency with plone 6, subsite also get folder icon
.pat-relateditems-result-title.contenttype-subsite,
#search-results .contenttype-subsite {
  background: no-repeat transparent 0px -649px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
.pat-relateditems-result-title.contenttype-link,
#search-results .contenttype-link {
  background: no-repeat transparent 0px -1081px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
.pat-relateditems-result-title.contenttype-news.item,
#search-results .contenttype-news.item {
  background: no-repeat transparent 0px -1297px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
/*.pat-relateditems-result-title.contenttype-collection {background: no-repeat transparent 0px -1729px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);}*/
.pat-relateditems-result-title.contenttype-collection,
#search-results .contenttype-collection {
  background: no-repeat transparent 0px -1729px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
.pat-relateditems-result-title.contenttype-plone-site {
  background-image: none;
}

.pat-relateditems-result-title.contenttype-file,
#search-results .contenttype-file {
  background: no-repeat transparent 0px -428px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}
.pat-relateditems-result-title.contenttype-image,
#search-results .contenttype-image {
  background: no-repeat transparent 0px -860px url(https://stijl.kuleuven.be/_i/wms/contenttypes-sprite.png);
}

.pat-relateditems-result-title.contenttype-document, .pat-relateditems-result-title.contenttype-event, .pat-relateditems-result-title.contenttype-folder, .pat-relateditems-result-title.contenttype-subsite, .pat-relateditems-result-title.contenttype-link, .pat-relateditems-result-title.contenttype-link, .pat-relateditems-result-title.contenttype-news, .pat-relateditems-result-title.contenttype-plone-site,
/*.pat-relateditems-result-title.contenttype-collection,*/.pat-relateditems-result-title.contenttype-collection, .pat-relateditems-result-title.contenttype-file, .pat-relateditems-result-title.contenttype-image {
  padding-left: 20px;
}

#search-results .contenttype-document, #search-results .contenttype-event, #search-results .contenttype-folder,  #search-results .contenttype-subsite, #search-results .contenttype-link, #search-results .contenttype-link, #search-results .contenttype-news, #search-results .contenttype-plone-site,
/*#search-results .contenttype-collection,*/#search-results .contenttype-collection, #search-results .contenttype-file, #search-results .contenttype-image {
  padding-left: 20px;
}

/* @group Search results page */
#search-filter {
  background: #eee;
  margin: 0;
  padding: 0;
  border: 1px solid #eee;
}
#content #search-filter fieldset {
  border: none !important;
  position: relative;
  padding: 0;
  margin: 0;
  text-align: left;
}

#content #search-filter legend {
  font-size: 1.1em;
  padding: 0.5em 0.5em;
  margin-bottom: 0;
  color: #333;
}
#search-filter .field {
  background: #fff;
  overflow: hidden;
  padding: 1em 1em;
  margin: 0;
}
#search-filter .formControls {
  text-align: center;
}
#search-results-bar > * {
  float: left;
}
#search-results-bar {
  position: relative;
  z-index: 1;
  float: left;
  width: 100%;
  padding: 1em 0;
}
#search-results {
  padding: 1em;
}
.searchResults {
  margin-top: 0;
}
#ajax-search-res {
  display: none;
}

/* custom search */
#search-field input.searchPage {
  width: 75%;
  float: left;
}
#search-field input.searchButton {
  width: 20%;
  margin: 0 0 0 1em;
}
#rss-subscription img {
  margin-top: 5px;
}
#searchResultsSort {
  background: #eee;
  padding: 1em 1em;
  margin-bottom: 1em;
}
#search-filter label {
  font-weight: normal;
}
#search-filter .optionsToggle {
  font-style: italic;
  color: #666;
}

.formHelp {
  display: block;
  font-weight: normal;
}

#searchform fieldset {
  text-align: center;
  border: 1px solid #eee !important;
}

#searchform input[type="submit"],
#searchform input[type="reset"] {
  border-radius: 24px;
  padding: 10px 16px;
}

.state-private {
  color: var(--plone-state-private) !important;
}

// for publishing date text under title in search results
.discreet {
  font-size: 0.8em;
  color: #666;
  font-weight: normal;
}

/* @end */

// PORTLETS
/* hide all images from footer column, except for social media icons */
#subnav {
  img,
  svg {
    display: none;
  }
}

/* hide group portlets and content type portlets form portlet management view */
.portlets-settings .portlet-group:nth-of-type(n + 2) {
  display: none;
}
.template-topbar-manage-portlets #quicknav {
  display: none;
}

/* styling for portlet actions (order, hide, delete) */
form.portlet-action {
  button {
    color: #004070;
    border: 1px solid #004070;

    &:hover,
    &:focus {
      color: #004070;
      border: 1px solid #004070;
    }
  }
}
/* @end */

/* fix styling in edit dropdowns (folder contents filter and widgets for tags, ... */
.select2-results .select2-highlighted {
  color: #fff;
  background: gray;
}

/* Status messages */
.portalMessage {
  font-size: 90%;
  background-color: #fd7;
  border: 1px solid #d80;
  margin: 0 0 0.5em 0;
  padding: 0.5em 0.75em;
}
.portalMessage a {
  color: black;
  border: none;
  text-decoration: underline;
}
/* @end */

/* eea facetedsearch */
#content .faceted-form {
  fieldset {
    border: 0;
    legend {
      font-family: Source Serif Pro, serif;
      font-weight: normal;
      color: #004070;
    }
  }
  .faceted-checkboxes-widget .widget-fieldset ul {
    margin: 1em;
  }
}

/* kulak logo footer */
.local-footer {
  .flogo {
    line-height: 44px;
    margin-bottom: 1em;

    &:empty {
      margin-bottom: 0;
    }
  }
}
