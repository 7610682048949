body.mykuleuven {
  /* make dropdowns in globale header visible - overrides barcelonetae _sitenav.scss */
  .global-header {
    .navbar-nav li ul.dropdown-menu.show {
      visibility: inherit;
      opacity: inherit;
    }
  }

  &.plone-toolbar-left .global-header {
    left: 60px;
  }
  @include media-breakpoint-up(md) {
    &.plone-toolbar-left-expanded .global-header {
      left: 220px;
    }
  }
}
